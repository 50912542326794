





























import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

export default Vue.extend({
  name: "Dashboard",
  components: {
  },
  props: {
    msg: String
  },
  data() {
    return {
      showValidationMessage: false,
      messageSent: false,
      submitting: false,
      name: "", 
      email: "",
      when: "",
      message: "", 
      serviceID: "12",
      templateID: "abc",
      userID: "user_5PhNJlkByhuk4RmX4YBNg",
      images: [
        "https://deniserweuve.files.wordpress.com/2014/04/picture-32.png",
        "https://poemsforwarriors.files.wordpress.com/2019/01/seuss_marshillonline.jpg"
      ],
      selectedImage: ''
    };
  },
  mounted() {
    document.title = "RacePass Event Registration - Holding Page";
    let fontawesomeScript = document.createElement("script");
    fontawesomeScript.setAttribute("src", "https://use.fontawesome.com/2eac59d7a6.js");
    document.head.appendChild(fontawesomeScript);

    let cssStyling = document.createElement("link");
    cssStyling.setAttribute("rel", "stylesheet");
    cssStyling.setAttribute("href", "https://use.fontawesome.com/releases/v5.15.2/css/all.css");

    let cssStylingShim = document.createElement("link");
    cssStylingShim.setAttribute("rel", "stylesheet");
    cssStylingShim.setAttribute("href", "https://use.fontawesome.com/releases/v5.15.2/css/v4-shims.css");

    document.head.appendChild(cssStyling);
    document.head.appendChild(cssStylingShim);
  }, 
  created() {
    const idx = Math.floor(Math.random() * this.images.length)
    this.selectedImage = this.images[idx]
  },
  methods: {
    submitForm: function(e: any) {
      e.preventDefault();
      this.showValidationMessage = false;
      this.submitting = true;

      if(!this.name || !this.email || !this.message || !this.when) {
        console.log("error")
        this.showValidationMessage = true;
        this.submitting = false;
        return;
      }

      let userRequest = document.getElementById("ActivationForm");

      var request: any = {};
      request.message = this.message;
      request.from = this.name;
      request.email = this.email;
      request.when = this.when;

      var formData = new FormData();
      formData.append('service_id', "service_0s40g5o");
      formData.append('template_id', "RacePassHolding_ID");
      formData.append('user_id', "user_5PhNJlkByhuk4RmX4YBNg");
      formData.append('message', JSON.stringify(request));

      axios.post("https://api.emailjs.com/api/v1.0/email/send-form", 
      formData).then(response => {
        // console.log(response);
        this.messageSent = true;
        setTimeout(() => {  window.location.href = window.origin; }, 3000);
      });
      
    }
  }
});

